import React, { useContext } from 'react'
import { FirebaseContext } from 'src/utils/firebase'
import ErrorBoundary from 'src/components/Molecules/ErrorBoundary'
import { User } from 'src/types/User'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import ProfileForm from 'src/components/Views/Profile/Form'
import { userInitialValues } from 'src/components/Views/Profile/Model'

export default function ProfileEditForm() {
  const { firebase, user } = useContext(FirebaseContext)

  const [userDoc, loading, error] = useDocumentData<User>(
    firebase.firestore().collection('users').doc(user?.uid),
    {
      idField: 'id'
    }
  )

  if (error) {
    return <ErrorBoundary error={error} />
  }
  return (
    <>{!loading && <ProfileForm initialValues={userDoc ?? userInitialValues} id={user?.uid} />}</>
  )
}
