import * as Yup from 'yup'
import { User } from 'src/types/User'

const phoneRegExp = /^\+[0-9]{2} *[0-9]{2} *[0-9]{3} *[0-9]{2} *[0-9]{2}$/

export const userFormModel: any = {
  formId: 'userForm',
  fields: {
    firstName: {
      label: 'Prénom',
      validation: Yup.string().required('Ce champ est requis'),
      initialValue: ''
    },
    lastName: {
      label: 'Nom',
      validation: Yup.string().required('Ce champ est requis'),
      initialValue: ''
    },
    email: {
      label: 'Email',
      validation: Yup.string()
        .email("L'email n'a pas le bon format")
        .required('Ce champ est requis'),
      initialValue: ''
    },
    phoneNumber: {
      label: 'Téléphone',
      validation: Yup.string().matches(phoneRegExp, "Le numéro de téléphone n'est pas valide"),
      initialValue: ''
    },
    password: {
      label: 'Mot de passe',
      validation: Yup.string()
        .min(8, 'Le mot de passe doit contenir 8 caractères au minimum')
        .nullable(),
      initialValue: ''
    }
  }
}

// We remove some properties from the User model
export interface UserFormModel extends Omit<User, 'id'> {
  password?: string
}

// Initial values and validation schema
export const userInitialValues = getConfiguration('initialValue') as UserFormModel
export const userValidationSchema = Yup.object(getConfiguration('validation'))

function getConfiguration(key: string) {
  const validationRules: { [key: string]: any } = {}
  for (const prop in userFormModel.fields) {
    if (userFormModel.fields.hasOwnProperty(prop) && userFormModel.fields[prop][key]) {
      validationRules[prop] = userFormModel.fields[prop][key]
    }
  }
  return validationRules
}
