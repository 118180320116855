import React from 'react'
import { at } from 'lodash'
import { useField } from 'formik'
import { TextField, Theme } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { FieldSchema } from 'src/types/FieldSchema'
import InputMask from 'react-input-mask'

type Props = {
  schema: FieldSchema
  name: string
  errorText?: string
  fullWidth?: boolean
  padding?: number
}

export default function PhoneField(props: Props) {
  const {
    errorText,
    name,
    schema: { label },
    ...rest
  } = props
  const [field, meta, helper] = useField(name)
  const classes = useStyles({ padding: props?.padding ?? 0 })

  function renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error')
    if (touched && error) {
      return error
    }
  }

  return (
    <InputMask
      mask="+41 99 999 99 99"
      value={field.value}
      onChange={(e) => {
        helper.setValue(e.target.value)
      }}
      placeholder="00 000 00 00">
      {() => (
        <TextField
          label={label}
          className={classes.buttonPadding}
          error={meta.touched && meta.error !== undefined && true}
          helperText={renderHelperText()}
          {...rest}
        />
      )}
    </InputMask>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonPadding: {
      paddingBottom: (props: any) => theme.spacing(props.padding)
    }
  })
)
