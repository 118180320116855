import React, { useContext, useState } from 'react'
import { Form, Formik } from 'formik'
import { Button, CircularProgress, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import commonStyles from 'src/styles/commonStyles'
import { InputField } from 'src/components/Molecules/FormFields'
import { FirebaseContext } from 'src/utils/firebase'
import FlashMessageQueue, { FlashMessage } from 'src/components/Molecules/FlashMessageQueue'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import {
  UserFormModel,
  userFormModel,
  userValidationSchema
} from 'src/components/Views/Profile/Model'
import PhoneField from 'src/components/Molecules/FormFields/PhoneField'
import PasswordField from 'src/components/Molecules/FormFields/PasswordField'
import ConfirmDialog from 'src/components/Molecules/ConfirmDialog'

type Props = {
  initialValues: UserFormModel
  id?: string
}

export default function ProfileForm({ initialValues, id }: Props) {
  const { firebase, logout } = useContext(FirebaseContext)
  const { formId, fields } = userFormModel
  const [t] = useTranslation()
  const commonClasses = commonStyles()
  const [flashMessages, setFlashMessages] = useState<FlashMessage[]>([])
  const classes = useStyles()

  type State = {
    formValues: UserFormModel | null
  }
  const [state, setState] = useState<State>({
    formValues: null
  })

  const setFormValues = (values: UserFormModel | null) => {
    setState({ ...state, ['formValues']: values })
  }

  async function handleSubmit(values: any, {}: any) {
    try {
      if (values?.password !== initialValues.password) {
        setFormValues(values)
      } else {
        values.updatedAt = firebase.firestore.Timestamp.now()
        await firebase
          .firestore()
          .collection('users')
          .doc(id)
          .set(values, { merge: true })
          .then(() => {
            const message: FlashMessage = {
              message: t('message.success')
            }
            setFlashMessages([message])
          })
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return (
    <>
      <ConfirmDialog
        type="logout"
        open={state.formValues !== null}
        onPerformAction={async () => {
          try {
            if (state.formValues) {
              const values = state.formValues
              values.updatedAt = firebase.firestore.Timestamp.now()
              await firebase
                .firestore()
                .collection('users')
                .doc(id)
                .set(values, { merge: true })
                .then(() => {
                  logout()
                })
            }
          } catch (e) {
            return Promise.reject(e)
          }
        }}
        onCloseAction={() => {
          // we simply close the form
          setFormValues(null)
        }}
      />
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={userValidationSchema}
        onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form id={formId}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <InputField name="firstName" schema={fields.firstName} fullWidth />
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputField name="lastName" schema={fields.lastName} fullWidth />
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputField name="email" schema={fields.email} fullWidth />
              </Grid>

              <Grid item xs={12} sm={6} className={classes.noSpace} />

              <Grid item xs={12} sm={6}>
                <PhoneField name="phoneNumber" schema={fields.phoneNumber} fullWidth />
              </Grid>

              <Grid item xs={12} sm={6}>
                <PasswordField name="password" schema={fields.password} fullWidth />
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} className={classes.right}>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={'classes.button'}>
                  {t('action.update')}
                </Button>
                {isSubmitting && (
                  <CircularProgress size={24} className={commonClasses.buttonProgress} />
                )}
              </Grid>
            </Grid>

            {flashMessages.length > 0 && <FlashMessageQueue messages={flashMessages} />}
          </Form>
        )}
      </Formik>
    </>
  )
}

const useStyles = makeStyles(({}: Theme) =>
  createStyles({
    noSpace: {
      padding: '0 !important'
    },
    right: {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  })
)
