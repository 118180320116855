import React, { ReactElement } from 'react'
import TopBar from 'src/components/Molecules/TopBar'
import { Container, Typography } from '@material-ui/core'
import AppFooter from 'src/components/Molecules/Footer'
import { useTranslation } from 'react-i18next'
import commonStyles from 'src/styles/commonStyles'
import Layout from 'src/components/Layout'
import PrivateRoute from 'src/components/PrivateRoute'
import ProfileEditForm from 'src/components/Views/Profile/EditForm'
import Page from 'src/components/Page'

export default function ProfilePage(): ReactElement {
  const [t] = useTranslation()
  const commonClasses = commonStyles()

  return (
    <Layout>
      <Page>
        <PrivateRoute path="/login">
          <TopBar />
          <Container component="main" className={commonClasses.mainContentContainer}>
            <Typography variant={'h1'}>{t('profile.moduleTitle')}</Typography>
            <ProfileEditForm />
          </Container>
          <AppFooter />
        </PrivateRoute>
      </Page>
    </Layout>
  )
}
