import React from 'react'
import { at } from 'lodash'
import { useField } from 'formik'
import {
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Theme
} from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { FieldSchema } from 'src/types/FieldSchema'
import { Visibility, VisibilityOff } from '@material-ui/icons'

type Props = {
  schema: FieldSchema
  name: string
  label?: string
  errorText?: string
  type?: string
  fullWidth?: boolean
  padding?: number
  multiline?: boolean
  rows?: number
}

export default function PasswordField(props: Props) {
  const {
    errorText,
    name,
    fullWidth,
    schema: { label },
    ...rest
  } = props
  const [field, meta] = useField(name)
  const classes = useStyles({ padding: props?.padding ?? 0 })

  function renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error')
    if (touched && error) {
      return error
    }
  }

  interface State {
    showPassword: boolean
  }

  const [values, setValues] = React.useState<State>({
    showPassword: false
  })

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <FormControl
      error={meta.touched && meta.error !== undefined && true}
      fullWidth={fullWidth ?? false}>
      <InputLabel htmlFor="standard-adornment-password">{label}</InputLabel>
      <Input
        id="standard-adornment-password"
        type={values.showPassword ? 'text' : 'password'}
        className={classes.buttonPadding}
        error={meta.touched && meta.error !== undefined && true}
        label={label}
        {...field}
        {...rest}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}>
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText id="component-error-text">{renderHelperText()}</FormHelperText>
    </FormControl>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonPadding: {
      paddingBottom: (props: any) => theme.spacing(props.padding)
    }
  })
)
